var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { noHeader: true, topClass: "topcolor-blue" },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.param.editable,
                        label: "내용물",
                        name: "inMaterials",
                        rows: 5,
                      },
                      model: {
                        value: _vm.item.inMaterials,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "inMaterials", $$v)
                        },
                        expression: "item.inMaterials",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.param.editable,
                        label: "용량",
                        name: "volume",
                        rows: 5,
                      },
                      model: {
                        value: _vm.item.volume,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "volume", $$v)
                        },
                        expression: "item.volume",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-text", {
                      attrs: {
                        suffix: "MPa",
                        editable: _vm.param.editable,
                        label: "압력(MPa)-운전",
                        name: "pressOperation",
                      },
                      model: {
                        value: _vm.item.pressOperation,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "pressOperation", $$v)
                        },
                        expression: "item.pressOperation",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-text", {
                      attrs: {
                        suffix: "MPa",
                        editable: _vm.param.editable,
                        label: "압력(MPa)-설계",
                        name: "pressDesign",
                      },
                      model: {
                        value: _vm.item.pressDesign,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "pressDesign", $$v)
                        },
                        expression: "item.pressDesign",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-text", {
                      attrs: {
                        suffix: "℃",
                        editable: _vm.param.editable,
                        label: "온도(℃)-운전",
                        name: "tempOperation",
                      },
                      model: {
                        value: _vm.item.tempOperation,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "tempOperation", $$v)
                        },
                        expression: "item.tempOperation",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-text", {
                      attrs: {
                        suffix: "℃",
                        editable: _vm.param.editable,
                        label: "온도(℃)-설계",
                        name: "tempDesign",
                      },
                      model: {
                        value: _vm.item.tempDesign,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "tempDesign", $$v)
                        },
                        expression: "item.tempDesign",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { noHeader: true, topClass: "topcolor-blue" },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-4" },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.param.editable,
                        label: "사용재질-본체",
                        name: "materialMain",
                        rows: 1,
                      },
                      model: {
                        value: _vm.item.materialMain,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "materialMain", $$v)
                        },
                        expression: "item.materialMain",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-4" },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.param.editable,
                        label: "사용재질-부속품",
                        name: "materialSubPart",
                        rows: 1,
                      },
                      model: {
                        value: _vm.item.materialSubPart,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "materialSubPart", $$v)
                        },
                        expression: "item.materialSubPart",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-4" },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.param.editable,
                        label: "사용재질-개스킷",
                        name: "materialGasket",
                        rows: 1,
                      },
                      model: {
                        value: _vm.item.materialGasket,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "materialGasket", $$v)
                        },
                        expression: "item.materialGasket",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { noHeader: true, topClass: "topcolor-blue" },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.param.editable,
                        label: "용법효율",
                        name: "weldingEfficiency",
                      },
                      model: {
                        value: _vm.item.weldingEfficiency,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "weldingEfficiency", $$v)
                        },
                        expression: "item.weldingEfficiency",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-text", {
                      attrs: {
                        suffix: "mm",
                        editable: _vm.param.editable,
                        label: "계산두께(mm)",
                        name: "calculateThick",
                      },
                      model: {
                        value: _vm.item.calculateThick,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "calculateThick", $$v)
                        },
                        expression: "item.calculateThick",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-text", {
                      attrs: {
                        suffix: "mm",
                        editable: _vm.param.editable,
                        label: "부식여유(mm)",
                        name: "corrosionAllowance",
                      },
                      model: {
                        value: _vm.item.corrosionAllowance,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "corrosionAllowance", $$v)
                        },
                        expression: "item.corrosionAllowance",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-text", {
                      attrs: {
                        suffix: "mm",
                        editable: _vm.param.editable,
                        label: "사용두께(mm)",
                        name: "useThick",
                      },
                      model: {
                        value: _vm.item.useThick,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "useThick", $$v)
                        },
                        expression: "item.useThick",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { noHeader: true, topClass: "topcolor-blue" },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-select", {
                      attrs: {
                        editable: _vm.param.editable,
                        comboItems: _vm.afterHeatTreatYnItems,
                        type: "edit",
                        itemText: "codeName",
                        itemValue: "code",
                        name: "afterHeatTreatYn",
                        label: "후열처리 여부",
                      },
                      model: {
                        value: _vm.item.afterHeatTreatYn,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "afterHeatTreatYn", $$v)
                        },
                        expression: "item.afterHeatTreatYn",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-text", {
                      attrs: {
                        suffix: "%",
                        editable: _vm.param.editable,
                        label: "비파괴검사율(%)",
                        name: "nondestructPercent",
                      },
                      model: {
                        value: _vm.item.nondestructPercent,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "nondestructPercent", $$v)
                        },
                        expression: "item.nondestructPercent",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.param.editable,
                        label: "적용법령",
                        name: "applicableLaws",
                      },
                      model: {
                        value: _vm.item.applicableLaws,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "applicableLaws", $$v)
                        },
                        expression: "item.applicableLaws",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }